import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LayoutWithSidebar from "../layout/withSidebar";
import Seo from "../components/seo";

const WorkPage = ({ data }) => {
  const pageTitle = "Work";
  const pageSubtitle =
    '"Do I really look like a guy with a plan?" - The Dark Knight, 2008';
  const posts = data.allMarkdownRemark.nodes;

  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo title="Work experience" />
      <div className="card-list">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug;
          const featuredImage = getImage(post.frontmatter.featuredImage);

          return (
            <div className="card card-list-item" key={post.fields.slug}>
              <div className="card-body">
                <article
                  className="card-list-article"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div className="featured-image-container">
                    <GatsbyImage
                      className="featured-image"
                      image={featuredImage}
                      alt={post.frontmatter.title}
                    />
                  </div>
                  <div className="info-container">
                    <header>
                      <h2>
                        <span itemProp="headline">{title}</span>
                      </h2>
                      <br></br>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                    <Link
                      className="stretched-link"
                      to={post.fields.slug}
                      itemProp="url"
                    >
                      <span>{title}</span>
                    </Link>
                  </div>
                </article>
              </div>
            </div>
          );
        })}
      </div>
    </LayoutWithSidebar>
  );
};

export default WorkPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "work" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1080
                quality: 100
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
